<style lang="scss" scoped>

</style>
<template>

    <div>
        <vehicle-service-form type="1" :auditId="auditId"></vehicle-service-form>
    </div>
</template>

<script type="text/ecmascript-6">
    import vehicleServiceForm from "../components/form/index"


    export default {
        //定义模版数据
        data() {
            return {
                auditId: ''
            }
        },
        components: {
            vehicleServiceForm
        },
        //计算属性
        computed: {


        },
        activated: async function () {
            if (this.auditId != this.$route.params.auditId) {
                this.auditId = this.$route.params.auditId;
            }
        },
        //主件被加载完成
        mounted: function () {
            this.auditId = this.$route.params.auditId;
        },
        //定义事件方法
        methods: {},
        //监听模版变量
        watch: {}

    }
</script>

